import React, { useContext, useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import axios from "../../axios";
import { AuthContext } from "../../AuthContext";
import { useNavigate } from "react-router-dom";
import { message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

const antIcon = <LoadingOutlined style={{ fontSize: 18 }} spin />;

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginStatus, setLoginStatus] = useState(false);

  const { token, setToken } = useContext(AuthContext);

  const navigate = useNavigate();

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const [messageApi, contextHolder] = message.useMessage();
  const success = (message) => {
    messageApi.open({
      type: "success",
      // content: 'This is a success message',
      content: message,
      style: {
        marginLeft: "75vw",
      },
    });
  };

  const error = (message) => {
    messageApi.open({
      type: "error",
      // content: 'This is an error message',
      content: message,
      style: {
        marginLeft: "75vw",
      },
    });
  };

  const handleLogin = async (username, password) => {
    setLoginStatus(true);
    try {
      const response = await axios.post("/auth/admin/login", {
        username: username,
        password: password,
      });
      setLoginStatus(false);
      localStorage.setItem("token", response.data.token);
      setToken(response.data.token);
      navigate("/");
    } catch (err) {
      console.log(err);
      setLoginStatus(false);
      error("Invalid username or password");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleLogin(username, password);
  };

  return (
    <>
      {contextHolder}
      <Container
        style={{
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Row className="justify-content-center">
          <Col xs={12} sm={8} md={6}>
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Label for="username">Username</Label>
                <Input
                  type="username"
                  name="username"
                  id="username"
                  value={username}
                  onChange={handleUsernameChange}
                  placeholder="Enter your username"
                />
              </FormGroup>
              <FormGroup>
                <Label for="password">Password</Label>
                <Input
                  type="password"
                  name="password"
                  id="password"
                  value={password}
                  onChange={handlePasswordChange}
                  placeholder="Enter your password"
                />
              </FormGroup>
              <Button type="submit" color="primary">
                {loginStatus && <Spin indicator={antIcon} size="large" style={{
                  color: "white",
                }} />}
                Login
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Login;
