import { useRoutes } from "react-router-dom";
import Themeroutes from "./routes/Router";
import { AuthContextProvider } from "./AuthContext";
import instance from "./axios";
import { LoaderContextProvider } from "./LoaderContext";

const App = () => {
  const routing = useRoutes(Themeroutes);

  return (
    <AuthContextProvider>
      <LoaderContextProvider>
        <div className="dark">{routing}</div>      
      </LoaderContextProvider>
    </AuthContextProvider>
  );
};

export default App;
