import { lazy } from "react";
import { Navigate } from "react-router-dom";
import Login from "../views/ui/Login.jsx";

/****Layouts*****/
const FullLayout = lazy(() => import("../layouts/FullLayout.js"));

/***** Pages ****/

const Starter = lazy(() => import("../views/Starter.js"));

const Items = lazy(() => import("../views/ui/Items"));
const AntdBids = lazy(() => import("../views/ui/AntdBids"));
const Users = lazy(() => import("../views/ui/Users"));
const Banners = lazy(() => import("../views/ui/Banners"));
const Categories = lazy(() => import("../views/ui/Categories"));
const Marketing = lazy(() => import("../views/ui/Marketing"));
const BulkItems = lazy(() => import("../views/ui/BulkItems"));
const BulkImages = lazy(() => import("../views/ui/BulkImages"));

/*****Routes******/

const ThemeRoutes = [
  { path: "/login", exact: true, element: <Login /> },
  {
    path: "/",
    element: <FullLayout />,
    children: [
      { path: "/", element: <Navigate to="/items" /> },
      { path: "/items", exact: true, element: <Items /> },
      { path: "/bids", exact: true, element: <AntdBids /> },
      { path: "/users", exact: true, element: <Users /> },
      { path: "/banners", exact: true, element: <Banners /> },
      { path: "/categories", exact: true, element: <Categories /> },
      { path: "/marketing", exact: true, element: <Marketing /> },
      { path: "/bulkItems", exact: true, element: <BulkItems /> },
      { path: "/bulkImages", exact: true, element: <BulkImages /> },

      { path: "/starter", exact: true, element: <Starter /> },
    ],
  },
];

export default ThemeRoutes;
