import axios from "axios";

const token = localStorage.getItem("token");

const axiosInstance = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? "http://192.168.18.106:5000"
      : "https://auction.api.madripple.com",
});
// const axiosInstance = axios.create({
//   baseURL:
//     process.env.NODE_ENV === "development"
//       ? "https://auction.api.madripple.com"
//       : "https://auction.api.madripple.com",
// });

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token") || "";
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem("token");
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
